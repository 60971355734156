@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

* {
  box-sizing: border-box;
  /* margin-right: auto;
  margin-left: auto; */
}

html {
  font-family: "Open Sans", sans-serif;
}

body {
  background: #4d4d4d;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
